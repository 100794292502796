import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, input, output } from '@angular/core'

@Component({
  selector: 'sb-infinite-scroll',
  standalone: true,
  imports: [],
  template: ` <div #element style="height: 1px"></div> `,
})
export class InfiniteScrollComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('element') element: ElementRef<HTMLDivElement>
  loadMore = output<void>()
  threshold = input<number>(0)
  #observer: IntersectionObserver

  ngOnInit(): void {
    this.createObserver()
  }

  ngAfterViewInit(): void {
    this.startObservingElements()
  }

  ngOnDestroy(): void {
    if (this.#observer) {
      this.#observer.disconnect()
      this.#observer = undefined
    }
  }

  private createObserver = (): void => {
    const options = {
      rootMargin: '0px',
      threshold: this.threshold(),
    }

    const isIntersecting = (entry: IntersectionObserverEntry) => entry.isIntersecting || entry.intersectionRatio > 0

    this.#observer = new IntersectionObserver((entries) => {
      const element = entries[0]
      if (isIntersecting(element)) {
        this.loadMore.emit()
      }
    }, options)
  }

  private startObservingElements = (): void => {
    if (!this.#observer) {
      return
    }

    this.#observer.observe(this.element.nativeElement)
  }
}
