import { SelectionModel } from '@angular/cdk/collections'
import { NgStyle, NgClass, NgTemplateOutlet } from '@angular/common'
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChild,
  EventEmitter,
  input,
  Input,
  output,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator'
import { MatSort, MatSortModule } from '@angular/material/sort'
import { MatTableDataSource, MatTableModule } from '@angular/material/table'
import { TranslocoModule } from '@jsverse/transloco'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { path, split } from 'ramda'

import { ActionType, DataTableActionItem } from '~common/utils/data-table'
import logger from '~core/utils/logger'

import { InfiniteScrollComponent } from '../../components/infinite-scroll/infinite-scroll.component'
import { AlertComponent } from '../alert/alert.component'
import { TextComponent } from '../text/text.component'
import { DataTableToolbarComponent } from './data-table-toolbar/data-table-toolbar.component'

export type DataTableSchemaItem = {
  key: string
  label?: string
  labelKey?: string
  sticky?: boolean
  stickyEnd?: boolean
  number?: boolean
  date?: boolean // todo
  textCenter?: boolean
  disableSorting?: boolean
}

export type DisplayedColumnItem = {
  key: string
  link?: string
}

@Component({
  selector: 'sb-data-table',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TextComponent,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    NgStyle,
    NgClass,
    NgTemplateOutlet,
    AlertComponent,
    DataTableToolbarComponent,
    MatPaginatorModule,
    TranslocoModule,
    InfiniteScrollComponent,
    NgxSkeletonLoaderModule,
  ],
  template: `
    @if (!noCount()) {
      <div class="flex justify-end">
        <sb-text variant="labelLarge" noMargin
          >{{ count() ?? dataSource.data?.length }} {{ 'words.results' | transloco }}</sb-text
        >
      </div>
    }
    @if (actions().length > 0) {
      <sb-data-table-toolbar [actions]="actions()" [selection]="selection" (onAction)="onAction.emit($event)" />
    }
    <table mat-table [dataSource]="dataSource" matSort [matSortDisabled]="!sortable()">
      <!-- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="primary"
            (change)="onMasterToggle()"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          />
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="onToggleRow(row)"
            [checked]="selection.isSelected(row)"
          />
        </td>
      </ng-container>

      @for (schemaItem of schema(); track schemaItem.label; let i = $index) {
        <ng-container [matColumnDef]="schemaItem.key" [sticky]="schemaItem.sticky" [stickyEnd]="schemaItem.stickyEnd">
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngStyle]="{
              verticalAlign: headerVerticalAlign
            }"
            [ngClass]="{
              'number-cell-header': isNumberCell(schemaItem),
              'centered-cell': schemaItem.textCenter
            }"
            mat-sort-header
            [disabled]="schemaItem.disableSorting"
          >
            <div class="h-full">
              <ng-container
                [ngTemplateOutlet]="
                  schemaItem.labelKey ? headerCellTemplateRef || defaultCellTemplate : defaultCellTemplate
                "
                [ngTemplateOutletContext]="{ $implicit: schemaItem.labelKey ?? schemaItem.label, schemaItem }"
              />
            </div>
          </th>
          <td mat-cell *matCellDef="let element" [colSpan]="element?.type === 'sectionHeader' ? 1 : 1">
            <div
              [ngClass]="{
                'text-right': isNumberCell(schemaItem),
                'text-center': schemaItem.textCenter
              }"
            >
              <ng-container
                [ngTemplateOutlet]="cellTemplateRef || defaultCellTemplate"
                [ngTemplateOutletContext]="{
                  $implicit: cellTemplateRef ? element : getValue(element, schemaItem.key),
                  schema: schemaItem
                }"
              />
            </div>
          </td>
        </ng-container>
      }

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: stickyHeader()"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onClickRow(row)"
        [ngClass]="{
          clickable: clickable() || singleSelectable(),
          selected: selection.isSelected(row),
          odd: isOddRow(row),
          'custom-odd-bg': row?.odd !== undefined
        }"
      ></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          @if (!isFetching()) {
            <sb-alert variant="neutral" marginClass="mb-0">
              {{ noDataMessage ?? ('words.noResult' | transloco) }}
            </sb-alert>
          }
        </td>
      </tr>
    </table>

    @if (isFetching()) {
      <div [ngStyle]="{ 'margin-top': '12px' }">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'background-color': '#edeef1',
            height: skeletonHeight(),
            margin: '3px 0',
            'border-radius': '12px'
          }"
        />
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'background-color': '#edeef1',
            height: skeletonHeight(),
            margin: '3px 0',
            'border-radius': '12px'
          }"
        />
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            'background-color': '#edeef1',
            height: skeletonHeight(),
            margin: '3px 0',
            'border-radius': '12px'
          }"
        />
      </div>
    }
    @if (infiniteScroll()) {
      <sb-infinite-scroll (loadMore)="loadMore.emit()" />
    } @else if (paginated()) {
      <mat-paginator [pageSize]="pageSize" [hidePageSize]="hidePageSize" [pageSizeOptions]="[5, 10, 20]" />
    }

    <ng-template #defaultCellTemplate let-content>{{ content }}</ng-template>
  `,
})
export class DataTableComponent<T> implements AfterViewInit {
  @ContentChild('cellTemplate') cellTemplateRef?: TemplateRef<unknown>
  dataSource: MatTableDataSource<T> = new MatTableDataSource<T>()
  @ContentChild('headerCellTemplate') headerCellTemplateRef?: TemplateRef<unknown>
  @Input() headerVerticalAlign: 'top' | 'baseline' | 'middle' | 'bottom' = 'middle'
  @Input() hidePageSize: boolean = true
  @Input() noDataMessage: string
  @Input() pageSize: number = 15
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator
  @Output() rowClick: EventEmitter<T> = new EventEmitter()
  schema = input<DataTableSchemaItem[]>()
  @Output() selected: EventEmitter<T[]> = new EventEmitter()
  selection = new SelectionModel<T>(true, [])
  @ViewChild(MatSort, { static: false }) sortHandler: MatSort
  infiniteScroll = input(false, { transform: booleanAttribute })
  skeletonHeight = input<string>('44px')
  loadMore = output<void>()
  isFetching = input<boolean>(false)
  actions = input<DataTableActionItem[]>([])
  onAction = output<DataTableActionItem>()
  clickable = input(false, { transform: booleanAttribute })
  noCount = input(false, { transform: booleanAttribute })
  count = input<number>()
  paginated = input(false, { transform: booleanAttribute })
  selectable = input(false, { transform: booleanAttribute })
  singleSelectable = input(false, { transform: booleanAttribute })
  sortable = input(false, { transform: booleanAttribute })
  stickyHeader = input(false, { transform: booleanAttribute })
  client = input(false, { transform: booleanAttribute })
  sort = output<MatSort>()

  private _diplayedColums: string[]

  bulkActions = computed(() => {
    const actions = this.actions().filter((action) => action.type === ActionType.BULK)
    logger.debug('bulkActions', actions)
    return actions
  })

  // TODO: implement record actions in the future

  @Input()
  set data(data: T[]) {
    this.selection.clear()
    this.dataSource.data = data
  }

  get displayedColumns(): string[] {
    return this.selectable() ? ['select', ...this._diplayedColums] : this._diplayedColums
  }

  @Input()
  set displayedColumns(value: string[]) {
    this._diplayedColums = value
  }

  getValue(row: T, key: DataTableSchemaItem['key']) {
    return path(split('.', key), row)
  }

  isNumberCell(column: DataTableSchemaItem) {
    return column.number && !column.textCenter
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    return numSelected === numRows
  }

  isOddRow(row) {
    if (row?.odd !== undefined) {
      return row.odd
    }
    return undefined
  }

  ngAfterViewInit() {
    if (this.paginated()) {
      this.dataSource.paginator = this.paginator
    }
    if (this.sortable()) {
      if (this.paginated()) {
        this.sortHandler.sortChange.subscribe(() => (this.paginator.pageIndex = 0))
      }

      if (this.client()) {
        this.dataSource.sort = this.sortHandler
      } else {
        this.sortHandler.sortChange.subscribe(() => this.sort.emit(this.sortHandler))
      }
    }
  }

  onClickRow(row: T) {
    if (this.singleSelectable()) {
      if (this.selection.isSelected(row)) {
        this.selection.deselect(row)
      } else {
        this.selection.clear()
        this.selection.select(row)
      }
      this.selected.emit(this.selection.selected)
    }
    this.rowClick.emit(row)
  }

  onMasterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row))
    this.selected.emit(this.selection.selected)
  }

  onToggleRow(row: T) {
    this.selection.toggle(row)
    this.selected.emit(this.selection.selected)
  }
}
